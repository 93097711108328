.container {
    padding: 20px;
  }
  
  .userCard {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .userCard h3 {
    color: #333;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .userCard p {
    margin-bottom: 5px;
  }
  
  .userCard button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .userCard button:hover {
    background-color: #0056b3;
  }