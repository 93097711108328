.container {
    padding: 20px;
  }
  
  .loading {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 12px;
    margin-top: 20px;
  }
  
  .alertList {
    margin-top: 20px;
  }
  
  .alertItem {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .alertIcon {
    font-size: 24px;
    margin-right: 12px;
  }
  
  .alertText {
    flex-grow: 1;
  }
  
  .dismissButton {
    padding: 6px 12px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .dismissButton:hover {
    background-color: #c82333;
  }
  
  .noAlerts {
    text-align: center;
    color: #6c757d;
    font-style: italic;
  }
  
  @media (max-width: 768px) {
    .alertItem {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .dismissButton {
      margin-top: 10px;
      align-self: flex-end;
    }
  }