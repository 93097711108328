.container {
    padding: 20px;
  }
  
  .roleCard {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .roleCard h3 {
    color: #333;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .roleCard ul {
    padding-left: 20px;
  }
  
  .roleCard li {
    margin-bottom: 5px;
  }