.container {
    padding: 20px;
  }
  
  .loading {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 12px;
    margin-top: 20px;
  }
  
  .form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .form input,
  .form select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form button:hover {
    background-color: #0056b3;
  }
  
  .transactionTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .transactionTable th,
  .transactionTable td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .transactionTable th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .transactionTable tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .transactionTable tr:hover {
    background-color: #f5f5f5;
  }
  
  @media (max-width: 768px) {
    .form {
      flex-direction: column;
    }
  
    .transactionTable {
      font-size: 14px;
    }
  
    .transactionTable th,
    .transactionTable td {
      padding: 8px;
    }
  }