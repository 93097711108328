.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
  }
  
  .form input {
    flex: 1;
    min-width: 120px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .form button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .form button:hover {
    background-color: #45a049;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tr:hover {
    background-color: #f5f5f5;
  }
  
  .managementButton, .analyticsButton, .inventoryButton {
    margin-top: 20px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .managementButton {
    background-color: #2196F3;
  }
  
  .analyticsButton {
    background-color: #FF9800;
  }
  
  .inventoryButton {
    background-color: #9C27B0;
  }
  
  .managementButton:hover {
    background-color: #1E88E5;
  }
  
  .analyticsButton:hover {
    background-color: #F57C00;
  }
  
  .inventoryButton:hover {
    background-color: #8E24AA;
  }
  
  @media (max-width: 768px) {
    .form {
      flex-direction: column;
    }
    
    .form input, .form button {
      width: 100%;
    }
  }