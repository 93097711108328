/* InventoryTracking.module.css */
.container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .loading, .error {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .addForm {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .addForm input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .addForm button {
    grid-column: span 3;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .addForm button:hover {
    background-color: #45a049;
  }
  
  .searchInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .inventoryTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .inventoryTable th, .inventoryTable td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .inventoryTable th {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  
  .inventoryTable th:hover {
    background-color: #ddd;
  }
  
  .inventoryTable tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .inventoryTable tr:hover {
    background-color: #f5f5f5;
  }
  
  .editBtn, .deleteBtn, .saveBtn, .cancelBtn {
    padding: 6px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .editBtn {
    background-color: #FFC107;
  }
  
  .deleteBtn {
    background-color: #F44336;
    color: white;
  }
  
  .saveBtn {
    background-color: #4CAF50;
    color: white;
  }
  
  .cancelBtn {
    background-color: #607D8B;
    color: white;
  }
  
  .editBtn:hover, .deleteBtn:hover, .saveBtn:hover, .cancelBtn:hover {
    opacity: 0.8;
  }