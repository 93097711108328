/* CooperativeProfile.module.css */
.container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .container h2 {
    color: #2c3e50;
    margin-top: 0;
  }
  
  .profileInfo {
    margin-top: 20px;
  }
  
  .profileInfo p {
    margin-bottom: 10px;
  }
  
  .profileInfo strong {
    color: #34495e;
  }
  
  .objectives {
    margin-top: 20px;
  }
  
  .objectives ul {
    padding-left: 20px;
  }
  
  .objectives li {
    margin-bottom: 5px;
  }