.container {
    padding: 20px;
  }
  
  .loading {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 12px;
    margin-top: 20px;
  }
  
  .form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .form input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex-grow: 1;
  }
  
  .form button {
    padding: 8px 16px;
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form button:hover {
    background-color: #138496;
  }
  
  .supplierTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .supplierTable th,
  .supplierTable td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .supplierTable th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .supplierTable tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .supplierTable tr:hover {
    background-color: #f5f5f5;
  }
  
  .deleteButton {
    padding: 6px 12px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .deleteButton:hover {
    background-color: #c82333;
  }
  
  @media (max-width: 768px) {
    .form {
      flex-direction: column;
    }
  
    .supplierTable {
      font-size: 14px;
    }
  
    .supplierTable th,
    .supplierTable td {
      padding: 8px;
    }
  }